import { render, staticRenderFns } from "./auth.vue?vue&type=template&id=0473cbf4&scoped=true"
import script from "./auth.vue?vue&type=script&lang=js"
export * from "./auth.vue?vue&type=script&lang=js"
import style0 from "./auth.vue?vue&type=style&index=0&id=0473cbf4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0473cbf4",
  null
  
)

/* custom blocks */
import block0 from "~/locales/common/auth.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fazp%2F_work%2F1%2Fs%2Fcomponents%2Fform%2Fauth.vue&lang=yaml&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormAuthProviders: require('/azp/_work/1/s/components/form/auth-providers.vue').default})
